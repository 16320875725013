@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
@import url('https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.css');
@import url('https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.Default.css');
@import url('https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css');

@font-face {
  font-family: NunitoSans;
  font-weight: 400;
  src: local("NunitoSans-Light"), url("./fonts/NunitoSansLight.ttf") format('truetype');
}

@font-face {
  font-family: NunitoSans;
  font-weight: 600;
  src: local("NunitoSans-SemiBold"), url("./fonts/NunitoSansSemiBold.ttf") format('truetype');
}

@font-face {
  font-family: NunitoSans;
  font-weight: 300;
  src: local("NunitoSans-ExtraLight"), url("./fonts/NunitoSansExtraLight.ttf") format('truetype');
}

@font-face {
  font-family: NunitoSans;
  font-weight: 500;
  src: local("NunitoSans-Regular"), url("./fonts/NunitoSansRegular.ttf") format('truetype');
}

@font-face {
  font-family: NunitoSans;
  font-weight: 700;
  src: local("NunitoSans-Bold"), url("./fonts/NunitoSansBold.ttf") format('truetype');
}

.leaflet-container {
  width: 100%;
  height: 95%;
}


a {
  color: #564eec;
}

body {
  margin: 0;
  font-family: 'NunitoSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#f4f4f2';
}

html {
  background-color: '#f4f4f2';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.euiComboBoxOptionItem {
  font-size: 11px;
}